export default [
  {
    path: '/order/home',
    name: 'order-home',
    component: () => import('@/views/order/OrderHome.vue'),
  },
  {
    path: '/order/ordering/:id/:name/:status/:order_number',
    name: 'order-ordering',
    component: () => import('@/views/order/Ordering.vue'),
  },
  {
    path: '/order/list',
    name: 'order-list',
    component: () => import('@/views/order/OrderList.vue'),
  },
  {
    path: '/manage/menu',
    name: 'manage-menu',
    component: () => import('@/views/manage/menu.vue'),
  },
]
